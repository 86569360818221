import { NavLink } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { FaInstagram } from "react-icons/fa";
import { UserContext } from "./UserContext";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

export default function Header() {
  const { setUserInfo } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu visibility

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/profile`, {
      credentials: "include",
    }).then((response) => {
      response.json().then((userInfo) => {
        setUserInfo(userInfo);
      });
    });
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle menu open state
  };

  return (
    <header className="header-wrapper">
      <NavLink className="header-wrapper-logo" to={"/"}>
        <img
          src="https://res.cloudinary.com/dipjef19s/image/upload/v1729065312/deskon_logo.png"
          alt=""
        />
      </NavLink>

      {/* Hamburger Icon */}
      <div className="hamburger" onClick={toggleMenu}>
        {isMenuOpen ? <IoClose /> : <RxHamburgerMenu />}{" "}
        {/* Show X or bars based on menu state */}
      </div>

      <nav className={`header-wrapper-nav ${isMenuOpen ? "active" : ""}`}>
        <NavLink
          to={"/"}
          exact="true"
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")} // Conditionally apply 'active' class
        >
          Spaces
        </NavLink>
        <NavLink
          to={"/products"}
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Products
        </NavLink>
        <NavLink
          to={"/community"}
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Community
        </NavLink>
        <NavLink
          to={"/submit"}
          onClick={toggleMenu}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Submit your space
        </NavLink>
        <div className="header-wrapper-social mobile">
          <a
            href="https://www.instagram.com/deskon.hub/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram /> Follow us!
          </a>
        </div>
      </nav>

      <div className="header-wrapper-social web">
        <a
          href="https://www.instagram.com/deskon.hub/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram /> Follow us!
        </a>
      </div>
    </header>
  );
}
