import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { IoLogOutOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";

import { UserContext } from "../UserContext"; // Import UserContext

export default function AdminLoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  const { setUserInfo } = useContext(UserContext); // Use UserContext to set user info
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  // Check if user is already logged in
  useEffect(() => {
    fetch(`${apiUrl}/profile`, {
      credentials: "include",
    }).then((response) => {
      response.json().then((userInfo) => {
        if (userInfo?.username) {
          setUserInfo(userInfo); // Set the user info in global context
          setRedirect(true);
        }
      });
    });
  }, [setUserInfo]);

  // Redirect to the upload page if user is logged in
  useEffect(() => {
    if (redirect) {
      navigate("/submit/admin/upload");
    }
  }, [redirect, navigate]);

  async function login(ev) {
    ev.preventDefault();

    const response = await fetch(`${apiUrl}/submit/admin`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.ok) {
      const userInfo = await response.json();
      setUserInfo(userInfo); // Set the user info in global context
      setRedirect(true); // Trigger redirect after successful login
    } else {
      alert("Login failed!");
    }
  }

  return (
    <div className="submit-page">
      <div className="submit-page-container">
        <div className="nav-back">
          <Link to={`/submit`}>
            <span>
              <IoIosArrowBack />
            </span>{" "}
            <span>Go back</span>
          </Link>
        </div>
        <div className="form-container">
          <form className="submit-page-form" onSubmit={login}>
            <div className="form-header-wrapper">
              <h2>Admin Login</h2>
              <p>Team DeskOn.</p>
            </div>
            <div className="form-input-wrapper">
              <div className="input-field-wrapper">
                <label htmlFor="username">Username</label>
                <input
                  id="username"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(ev) => setUsername(ev.target.value)}
                />
              </div>
              <div className="input-field-wrapper">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(ev) => setPassword(ev.target.value)}
                />
              </div>
            </div>
            <button className="form-btn">Login</button>
          </form>
        </div>
        {/* <Link className="admin-login-btn" to={"/submit/admin/register"}>
          Admin register <FaAngleRight />
        </Link> */}
      </div>
    </div>
  );
}
