import { useEffect, useState, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { UserContext } from "../UserContext";
import { IoIosArrowBack } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { LoadingSpinner } from "../components/LoadingSpinner";

export default function PostPage() {
  const [postInfo, setPostInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userInfo } = useContext(UserContext);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const cachedData = localStorage.getItem(`post_${id}`);
    if (cachedData) {
      setPostInfo(JSON.parse(cachedData));
      setLoading(false);
      return;
    }

    const controller = new AbortController();
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/post/${id}`, {
          signal: controller.signal,
        });
        if (!response.ok) throw new Error("Failed to fetch post data");
        const data = await response.json();

        // Cache the response to localStorage
        localStorage.setItem(`post_${id}`, JSON.stringify(data));
        setPostInfo(data);
      } catch (error) {
        if (error.name === "AbortError") return; // Fetch was canceled
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();

    // Clean up by aborting fetch on component unmount
    return () => controller.abort();
  }, [id, apiUrl]);

  if (loading) {
    return (
      <div className="page-padding loader">
        <div className="loading-spinner"></div>
        <p>Loading posts...</p>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  if (!postInfo) {
    return <div className="no-data-message">Post not found.</div>;
  }

  const isAuthor = userInfo?.id === postInfo.author._id;

  return (
    <main className="postpage">
      <section className="postpage-container">
        <header className="postpage-header">
          <div className="postpage-header-left">
            <Link to="/" className="nav-back">
              <IoIosArrowBack aria-hidden="true" /> <span>Back to home</span>
            </Link>
            <p className="header-serial">
              <span>Post </span>#{postInfo.serial}
            </p>
            <p className="header-author">
              <span>Posted by: </span>
              {postInfo.author.username}
            </p>
            <p className="header-date">
              <span>Created at: </span>
              {format(new Date(postInfo.createdAt), "MMM d, yyyy")}
            </p>
          </div>

          {isAuthor && (
            <div className="postpage-header-right">
              <Link className="edit-btn" to={`/edit/${postInfo._id}`}>
                <FiEdit aria-hidden="true" /> Edit post
              </Link>
            </div>
          )}
        </header>

        <article className="post">
          <figure className="postpage-img">
            <figcaption className="postpage-img-title">
              {postInfo.name}'s Workspace
            </figcaption>
            <img
              src={postInfo.taggedcover}
              alt={`${postInfo.name}'s workspace`}
              loading="lazy"
              onError={(e) => (e.target.src = "/default-image.jpg")}
            />
            <p className="postpage-cat">
              <span>Category: </span>#{postInfo.category}
            </p>
          </figure>

          <div className="postpage-info">
            <section className="postpage-credits">
              <p className="credits-name">
                <span>Workspace credits: </span>
                {postInfo.name}
              </p>
              <p className="credits-handle">
                <span>Instagram handle: </span>
                <p
                  className="social-links"
                  dangerouslySetInnerHTML={{ __html: postInfo.handle }}
                />
              </p>
            </section>

            <section className="postpage-description">
              <p dangerouslySetInnerHTML={{ __html: postInfo.desc }} />
            </section>

            <section className="postpage-links">
              <h3>Workspace Links</h3>
              <ul dangerouslySetInnerHTML={{ __html: postInfo.url }} />
            </section>

            <section className="postpage-disclaimer">
              Affiliate Disclaimer:{" "}
              <span>
                If you purchase a product through our links, we may receive a
                commission at no additional cost to you.
              </span>
            </section>
          </div>
        </article>
      </section>
    </main>
  );
}
