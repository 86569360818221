import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ProductHunt from "./components/ProductHunt";

export default function Layout() {
  return (
    <main>
      <Header />
      <div className="page-padding">
        <Outlet />
      </div>
      <ProductHunt />
      <Footer />
    </main>
  );
}
