import { NavLink } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";

export default function Footer() {
  return (
    <footer>
      <div className="footer-wrapper">
        <div className="footer-wrapper-top">
          <NavLink className="footer-logo" to={"/"}>
            <img
              src="https://res.cloudinary.com/dipjef19s/image/upload/v1729065312/deskon_logo.png"
              alt=""
            />
          </NavLink>
          <div className="footer-text">
            <span>Transforming workspaces into inspiration hubs. </span>
            <span>Design, and shop your dream desk setup with DeskOn.</span>
          </div>
          <div className="footer-social">
            <a
              href="https://www.instagram.com/deskon.hub/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram /> Follow us!
            </a>
          </div>
        </div>

        <div className="footer-wrapper-base">
          <p>
            &copy; 2024 <b>DeskOn</b>, All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
