import {
  useEffect,
  useState,
  lazy,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Post = lazy(() => import("./Post"));

export default function SpacesSection() {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [categories, setCategories] = useState(["All Spaces"]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const postsPerPage = 24;
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const location = useLocation();

  // Memoize category from URL parameters to avoid recalculations
  const category = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get("category") || "All Spaces";
  }, [location.search]);

  const handleNavigation = useCallback(
    (cat, page) => {
      navigate(`?category=${encodeURIComponent(cat)}&page=${page}`);
    },
    [navigate]
  );

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/submit/admin/upload`, {
          credentials: "include",
        });
        const data = await response.json();

        setPosts(data);
        setFilteredPosts(data);

        // Remove unwanted categories once to avoid recalculating
        const uniqueCategories = [
          ...new Set(
            data
              .map((post) => post.category)
              .filter((cat) => cat && cat !== "uncategorized")
          ),
        ];
        setCategories(["All Spaces", ...uniqueCategories]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [apiUrl]);

  // Debounced filtering by category to optimize
  useEffect(() => {
    const filterByCategory = () => {
      const filtered =
        category === "All Spaces"
          ? posts
          : posts.filter((post) => post.category === category);
      setFilteredPosts(filtered);
    };

    // Apply debouncing to avoid running too frequently
    const debounceFilter = setTimeout(filterByCategory, 300);
    return () => clearTimeout(debounceFilter);
  }, [category, posts]);

  const currentPosts = useMemo(() => {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage;
    return filteredPosts.slice(start, end);
  }, [filteredPosts, currentPage, postsPerPage]);

  const changeCategory = (cat) => {
    setCurrentPage(1);
    handleNavigation(cat, 1);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= Math.ceil(filteredPosts.length / postsPerPage)) {
      setCurrentPage(nextPage);
      handleNavigation(category, nextPage);
    }
  };

  const handlePreviousPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      setCurrentPage(prevPage);
      handleNavigation(category, prevPage);
    }
  };

  return (
    <section id="spaces-section" className="spaces-section">
      <div className="spaces-section-filter">
        <div className="filter-btns-wrapper">
          <div className="filter-btns">
            {categories.map((cat) => (
              <button
                key={cat}
                className={`filter-btn ${category === cat ? "active" : ""}`}
                onClick={() => changeCategory(cat)}
              >
                {cat}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="spaces-section-cards">
        {loading ? (
          <div className="loading">
            <div className="loading-spinner"></div>
            <p>Loading posts...</p>
          </div>
        ) : (
          <div className="cards-container">
            {currentPosts.length > 0 ? (
              currentPosts.map((post) => (
                <Suspense fallback={<div>Loading...</div>} key={post.id}>
                  <Post {...post} />
                </Suspense>
              ))
            ) : (
              <p>No posts found for this category.</p>
            )}
          </div>
        )}
      </div>

      <div className="pagination-btns">
        {currentPage > 1 && (
          <button
            className="pagination-btn pagination-previous"
            onClick={handlePreviousPage}
          >
            Previous
          </button>
        )}
        {currentPage < Math.ceil(filteredPosts.length / postsPerPage) && (
          <button
            className="pagination-btn pagination-next"
            onClick={handleNextPage}
          >
            Next
          </button>
        )}
      </div>
    </section>
  );
}
