export default function ProductHunt() {
  return (
    <div
      className="producthunt-float"
      style={{
        position: "fixed",
        top: "90%",
        right: "0%",
        transform: "translate(-10%, -50%)",
      }}
    >
      <a
        href="https://www.producthunt.com/posts/deskon?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-deskon"
        target="_blank"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=594986&theme=neutral"
          alt="DeskOn - Explore&#0032;tagged&#0032;workspaces&#0032;&#0038;&#0032;Shop&#0032;the&#0032;look&#0032;instantly | Product Hunt"
          style={{ width: "250px", height: "54px" }}
          width="250"
          height="54"
        />
      </a>
    </div>
  );
}
