import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import React, { useEffect } from "react";

const imageContainer = document.querySelector(".image-container");
const image3D = document.querySelector(".image-3d");

export default function HeroSection() {
  useEffect(() => {
    const imageContainer = document.querySelector(".image-container");
    const image3D = document.querySelector(".image-3d");

    if (!imageContainer || !image3D) {
      console.error("Image container or image 3D element not found");
      return;
    }

    const handleMouseMove = (event) => {
      const rect = imageContainer.getBoundingClientRect();
      const x = event.clientX - rect.left - rect.width / 2;
      const y = event.clientY - rect.top - rect.height / 2;

      const rotateX = -y / 20;
      const rotateY = x / 20;

      image3D.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleMouseLeave = () => {
      image3D.style.transform = "rotateX(0deg) rotateY(0deg)";
    };

    imageContainer.addEventListener("mousemove", handleMouseMove);
    imageContainer.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      imageContainer.removeEventListener("mousemove", handleMouseMove);
      imageContainer.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <section className="hero-section">
      <div className="hero-section-wrapper">
        <div className="hero-section-main">
          <p className="hero-section-text">Explore & Recreate</p>
          <h1 className="hero-section-header">Discover tagged workspaces</h1>
          <h1 className="hero-section-subheader">
            Let's you shop the look instantly
          </h1>
        </div>
      </div>
      <div className="hero-section-bg image-container">
        <img
          loading="lazy"
          src="your-image.jpg"
          alt="3D Image"
          class="image-3d"
          src="https://res.cloudinary.com/dipjef19s/image/upload/v1730280762/ororndosxr0pvo16nftl.png"
        />
      </div>
      <div className="hero-section-nav">
        <a href="#spaces-section">
          <FaChevronDown />
        </a>
      </div>
    </section>
  );
}
