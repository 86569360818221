import { useState, useEffect } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import Editor from "../Editor";

export default function EditPost() {
  const { id } = useParams();
  const [serial, setSerial] = useState("");
  const [name, setName] = useState("");
  const [handle, setHandle] = useState("");
  const [files, setFiles] = useState("");
  const [taggedfiles, setTaggedFiles] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [item, setItem] = useState("");
  const [redirect, setRedirect] = useState("");
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/post/` + id).then((response) => {
      response.json().then((postInfo) => {
        setSerial(postInfo.serial);
        setName(postInfo.name);
        setHandle(postInfo.handle);
        setFiles(postInfo.files);
        setTaggedFiles(postInfo.taggedfiles);
        setCategory(postInfo.category);
        setContent(postInfo.desc);
        setUrl(postInfo.url);
        setItem(postInfo.item);
      });
    });
  }, []);

  async function updatePost(ev) {
    ev.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.set("serial", serial);
    data.set("name", name);
    data.set("handle", handle);
    if (files?.[0]) {
      data.set("file", files?.[0]);
    }
    if (taggedfiles?.[0]) {
      data.set("taggedfile", taggedfiles?.[0]);
    }
    data.set("category", category);
    data.set("desc", content);
    data.set("url", url);
    data.set("item", item);
    data.set("id", id);

    try {
      const response = await fetch(`${apiUrl}/submit/admin/upload`, {
        method: "PUT",
        body: data,
        credentials: "include",
      });

      if (response.ok) {
        alert("Post updated successfully!");
        setRedirect(true);
      }
    } catch (err) {
      console.error("Error updating post:", err);
      alert("An error occurred while updating the post.");
    } finally {
      setLoading(false);
    }
  }

  if (redirect) {
    return <Navigate to={`/post/` + id} />;
  }

  return (
    <div className="submit-page">
      <div className="submit-page-container">
        <div className="form-container">
          <form className="submit-page-form" onSubmit={updatePost}>
            <div className="form-header-wrapper">
              <h2>Update post</h2>
              <p>Team Deskon</p>
            </div>
            <div className="form-input-wrapper">
              <div className="input-field-wrapper">
                <label for="post-sno">Post number</label>
                <input
                  id="post-sno"
                  type="text"
                  placeholder="Serial number"
                  value={serial}
                  onChange={(ev) => setSerial(ev.target.value)}
                  required
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-name">Post name</label>
                <input
                  id="post-name"
                  type="text"
                  placeholder="Username"
                  value={name}
                  onChange={(ev) => setName(ev.target.value)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-handle">Instagram handle</label>
                <Editor id="post-handle" onChange={setHandle} value={handle} />
              </div>

              <div className="input-field-wrapper">
                <label for="post-pic">Post picture</label>
                <input
                  id="post-pic"
                  type="file"
                  onChange={(ev) => setFiles(ev.target.files)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-pic-tagged">Post picture (tagged)</label>
                <input
                  id="post-pic-tagged"
                  type="file"
                  onChange={(ev) => setTaggedFiles(ev.target.files)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-category">Post category</label>
                <input
                  id="post-category"
                  type="text"
                  placeholder="Category"
                  value={category}
                  onChange={(ev) => setCategory(ev.target.value)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-desc">Post description</label>
                <Editor id="post-desc" onChange={setContent} value={content} />
              </div>
            </div>

            <div className="input-field-wrapper">
              <label for="post-url">Post URLs</label>
              <Editor id="post-url" onChange={setUrl} value={url} />
            </div>

            <div className="input-field-wrapper">
              <label for="post-item">Post items</label>
              <input
                id="post-item"
                type="text"
                placeholder="Item quantity"
                value={item}
                onChange={(ev) => setItem(ev.target.value)}
                required
              />
            </div>

            <button className="form-btn" disabled={loading}>
              {loading ? "Updating..." : "Update"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
