import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";

import { useNavigate, Navigate } from "react-router-dom";
import Editor from "../Editor";

import { UserContext } from "../UserContext";

// import Button from "react-bootstrap/Button";
// import "bootstrap/dist/css/bootstrap.min.css";

export default function UploadPage() {
  const [user, setUser] = useState(null);
  const [serial, setSerial] = useState("");
  const [name, setName] = useState("");
  const [handle, setHandle] = useState("");
  const [files, setFiles] = useState("");
  const [taggedfiles, setTaggedFiles] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [item, setItem] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [tohomepage, setToHomepage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUserInfo } = useContext(UserContext);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/profile`, {
      credentials: "include",
    }).then((response) => {
      response.json().then((userInfo) => {
        if (userInfo?.username) {
          setUser(userInfo.username);
          setRedirect(true);
        }
      });
    });
  }, []);

  async function upload(ev) {
    ev.preventDefault();
    setLoading(true); // Set loading to true when starting the upload

    const data = new FormData();
    data.set("serial", serial);
    data.set("name", name);
    data.set("handle", handle);
    data.set("file", files[0]);
    data.set("taggedfile", taggedfiles[0]);
    data.set("category", category);
    data.set("desc", content);
    data.set("url", url);
    data.set("item", item);

    try {
      const response = await fetch(`${apiUrl}/submit/admin/upload`, {
        method: "POST",
        body: data,
        credentials: "include",
      });

      if (response.ok) {
        alert("Successfully submitted!");
        setInterval(() => {
          setToHomepage(true);
        }, 1000);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoading(false); // Set loading to false after the upload completes
    }
  }

  if (tohomepage) {
    return <Navigate to={"/"} />;
  }

  function logout() {
    fetch(`${apiUrl}/submit/admin/logout`, {
      credentials: "include",
      method: "POST",
    }).then(() => {
      setUser(null);
      setRedirect(false); // Reset redirect on logout
      setUserInfo(null);
      navigate("/submit/admin"); // Navigate to login page after logout
    });
  }

  return (
    <div className="submit-page">
      <div className="submit-page-container">
        <div className="nav-back">
          <Link to={`/submit`}>
            <span>
              <IoIosArrowBack />
            </span>{" "}
            <span>Go back</span>
          </Link>
        </div>
        <div className="form-container">
          <form
            className="submit-page-form"
            enctype="multipart/form-data"
            onSubmit={upload}
          >
            <div className="form-header-wrapper">
              <h2>Post uploads</h2>
              <p>Team Deskon</p>
            </div>
            <div className="form-input-wrapper">
              <div className="input-field-wrapper">
                <label for="post-sno">Post number</label>
                <input
                  id="post-sno"
                  type="text"
                  placeholder="Serial number"
                  value={serial}
                  onChange={(ev) => setSerial(ev.target.value)}
                  required
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-name">Post name</label>
                <input
                  id="post-name"
                  type="text"
                  placeholder="Username"
                  value={name}
                  onChange={(ev) => setName(ev.target.value)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-handle">Instagram handle</label>
                <Editor id="post-handle" onChange={setHandle} value={handle} />
              </div>

              <div className="input-field-wrapper">
                <label for="post-pic">Post picture</label>
                <input
                  id="post-pic"
                  type="file"
                  name="file"
                  onChange={(ev) => setFiles(ev.target.files)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-pic-tagged">Post picture (tagged)</label>
                <input
                  id="post-pic-tagged"
                  type="file"
                  name="taggedfile"
                  onChange={(ev) => setTaggedFiles(ev.target.files)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-category">Post category</label>
                <input
                  id="post-category"
                  type="text"
                  placeholder="Category"
                  value={category}
                  onChange={(ev) => setCategory(ev.target.value)}
                />
              </div>

              <div className="input-field-wrapper">
                <label for="post-desc">Post description</label>
                <Editor id="post-desc" onChange={setContent} value={content} />
              </div>
            </div>

            <div className="input-field-wrapper">
              <label for="post-url">Post URLs</label>
              <Editor id="post-url" onChange={setUrl} value={url} />
            </div>

            <div className="input-field-wrapper">
              <label for="post-item">Post items</label>
              <input
                id="post-item"
                type="text"
                placeholder="Item quantity"
                value={item}
                onChange={(ev) => setItem(ev.target.value)}
                required
              />
            </div>

            <button className="form-btn" disabled={loading}>
              {loading ? "Uploading..." : "Post"}
            </button>
          </form>
        </div>
        <a onClick={logout}>
          Logout <IoLogOutOutline />
        </a>
      </div>
    </div>
  );
}
