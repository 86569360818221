import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function ProductsPage() {
  return (
    <div className="product-page">
      <div className="hero-section">
        <div className="nav-back">
          <Link to={`/`}>
            <span>
              <IoIosArrowBack />
            </span>{" "}
            <span>Back to home</span>
          </Link>
        </div>
        <h1>Coming soon...</h1>
      </div>
    </div>
  );
}
