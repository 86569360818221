import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import Swal from "sweetalert2";

export default function SubmitPage() {
  const [name, setName] = useState("");
  const [userid, setUserid] = useState("");
  const [email, setEmail] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Combined handler for both form submission and email request
  async function handleSubmit(ev) {
    ev.preventDefault();
    setLoading(true);

    try {
      // First, make the request to submit form data to your backend
      const response = await fetch(`${apiUrl}/submit`, {
        method: "POST",
        body: JSON.stringify({ name, userid, email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        // Next, trigger the email request
        const formData = new FormData(ev.target);
        formData.append("access_key", "a771cebd-314e-47fc-a164-08d59a8208e7");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        }).then((res) => res.json());

        if (res.success) {
          Swal.fire({
            title: "Successfully submitted!",
            text: "Our team will get back to you soon.",
            icon: "success",
          });

          setTimeout(() => {
            setRedirect(true);
          }, 1000);
        }
      } else {
        throw new Error("Form submission failed.");
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: "Failed to submit. Please try again later.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className="submit-page">
      <div className="submit-page-container">
        <div className="nav-back">
          <Link to={`/`}>
            <span>
              <IoIosArrowBack />
            </span>{" "}
            <span>Back to home</span>
          </Link>
        </div>
        <div className="form-container">
          <form className="submit-page-form" onSubmit={handleSubmit}>
            <div className="form-header-wrapper">
              <h2>
                Show us your workspace and get a chance to be featured on the
                community ✨
              </h2>
              <p>Please fill out the form below and we'll be in touch soon!</p>
            </div>
            <div className="form-input-wrapper">
              <div className="input-field-wrapper">
                <label htmlFor="submit-name">Your name</label>
                <input
                  id="submit-name"
                  type="text"
                  name="name"
                  required
                  placeholder="Matts Workspace"
                  value={name}
                  onChange={(ev) => setName(ev.target.value)}
                />
              </div>
              <div className="input-field-wrapper">
                <label htmlFor="submit-insta">Instagram handle</label>
                <input
                  id="submit-insta"
                  type="text"
                  name="userid"
                  required
                  placeholder="@matts.workspace"
                  value={userid}
                  onChange={(ev) => setUserid(ev.target.value)}
                />
              </div>
              <div className="input-field-wrapper">
                <label htmlFor="submit-email">Email address</label>
                <input
                  id="submit-email"
                  type="email"
                  name="email"
                  required
                  placeholder="matt@example.com"
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                />
              </div>
            </div>
            <button className="form-btn" disabled={loading}>
              {loading ? "Sending..." : "Get featured!"}
            </button>
          </form>
        </div>
        <Link className="admin-login-btn" to={"/submit/admin"}>
          Admin login <FaAngleRight />
        </Link>
      </div>
    </div>
  );
}
