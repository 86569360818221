import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";

import Layout from "./Layout";
import IndexPage from "./pages/IndexPage";
import SubmitPage from "./pages/SubmitPage";
import AdminLoginPage from "./pages/AdminLoginPage";
import AdminRegisterPage from "./pages/AdminRegisterPage";
import PostPage from "./pages/PostPage";
import UploadPage from "./pages/UploadPage";
import CommunityPage from "./pages/CommunityPage";
import ProductsPage from "./pages/ProductsPage";
import EditPost from "./pages/EditPost";
import { UserContextProvider } from "./UserContext";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route path="/submit" element={<SubmitPage />} />
          <Route path="/submit/admin" element={<AdminLoginPage />} />
          {/* <Route
            path="/submit/admin/register"
            element={<AdminRegisterPage />}
          /> */}
          <Route path="/submit/admin/upload" element={<UploadPage />} />
          <Route path="/post/:id" element={<PostPage />} />
          <Route path="/edit/:id" element={<EditPost />} />
          <Route path="/community" element={<CommunityPage />} />
          <Route path="/products" element={<ProductsPage />} />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;
