import { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import SpacesSection from "../components/SpacesSection";

export default function IndexPage() {
  return (
    <div className="main-container">
      <HeroSection />
      <SpacesSection />
    </div>
  );
}
